<template src="./EditGroupModal.html"> </template>

<script>
import idiService from "@/services/Idi";
import categoryService from "@/services/Category";

export default {
  name: "edit-group-modal",
  props: {
    openEditModal: { type: Boolean, default: false },
    nameProp: { type: String, default: "" },
    description: { type: String },
    category: { type: Object },
    coordinatorId: { type: String},
    groupId: { type: String },
    institutionId: { type: String },
    registrationNumber: { type: String },
    institutionName: { type: String }
  },
  data() {
    return {
      name: "",
      categoryList: null
    };
  },
  created() {
    this.getInitData();
  },
  methods: {
    async getInitData() {
      try {
        this.categoryList = await categoryService.getCategoriesWhitSubcategory();
        const institutions = await idiService.listInstitutions();
        this.institutionList = institutions;
      } catch (error) {
        console.error("created", error);
      }
    },
    closeModal() {
      this.$emit("closeTheEditGroupModal");
    },
    closeModalRefresh() {
      this.$emit("closeTheEditGroupModalAndRefresh");
    },
    async editGroup() {
      try {
        const obj = {
          name: this.nameProp,
          description: this.description,
          categoryId: this.category.id,
          institutionId: this.institutionId,
          coordinatorId: this.coordinatorId,
          registrationNumber: this.registrationNumber ? this.registrationNumber : "",
          institutionName: this.institutionName
        };
        await idiService.updateGroup(this.groupId, obj);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se editó el grupo con éxito",
          type: "is-success"
        });
        this.closeModalRefresh();
        location.reload();
        this.$router.go(0);
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Falló la edición del grupo.",
          type: "is-error"
        });
        this.closeModal();
      }
    }
  },
  computed: {
    disableButton() {
      return this.nameProp && this.description && this.category;
    }
  }
};
</script>

<style lang="scss" scoped src="./EditGroupModal.scss"></style>
